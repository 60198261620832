<template>
  <div>
    <!-- <h1>{{ $route.meta.title }}</h1> -->
    <dashboard v-if="form == 'dashboard'" :kokyaku_code="kokyaku_code" />
    <v-card v-else flat>
      <!-- <apo_houkoku
        v-if="form == 'apo_houkoku'"
        :kokyaku_code="kokyaku_code"
        @commit="onFormClose"
      /> -->
      <cloud_kadou_houkoku v-if="form == 'cloud_kadou_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <!-- <demo_houkoku
        v-if="form == 'demo_houkoku'"
        :kokyaku_code="kokyaku_code"
        @commit="onFormClose"
      /> -->
      <dounyuu_nittei_houkoku v-if="form == 'dounyuu_nittei_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <dounyuu_shuzai_kanryou_houkoku v-if="form == 'dounyuu_shuzai_kanryou_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <FacilityCreate v-if="form == 'FacilityCreate'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <FacilityEdit v-if="form == 'FacilityEdit'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <irai_uketsuke_houkoku v-if="form == 'irai_uketsuke_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <!-- <jyutyu_houkoku
        v-if="form == 'jyutyu_houkoku'"
        :kokyaku_code="kokyaku_code"
        @commit="onFormClose"
      /> -->
      <!-- <kakunin_houkoku
        v-if="form == 'kakunin_houkoku'"
        :kokyaku_code="kokyaku_code"
        @commit="onFormClose"
      /> -->
      <!-- <keijiban_zenin_haishin v-if="form == 'keijiban_zenin_haishin'" :kokyaku_code="kokyaku_code" @commit="onFormClose" /> -->
      <!-- <kousei_houkoku v-if="form == 'kousei_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" /> -->
      <!-- <lease_kaishi v-if="form == 'lease_kaishi'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <lease_kaishuu v-if="form == 'lease_kaishuu'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <lease_sinsa_kekka_touroku v-if="form == 'lease_sinsa_kekka_touroku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <lease_sinsa_teishutu v-if="form == 'lease_sinsa_teishutu'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <lease_teishutsu v-if="form == 'lease_teishutsu'" :kokyaku_code="kokyaku_code" @commit="onFormClose" /> -->
      <noKSRelation v-if="form == 'noKSRelation'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <!-- <nyuukin_kaishi v-if="form == 'nyuukin_kaishi'" :kokyaku_code="kokyaku_code" @commit="onFormClose" /> -->
      <service_kadou_houkoku v-if="form == 'service_kadou_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <service_shuryou_houkoku v-if="form == 'service_shuryou_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <!-- <shoubaikanri_jyutyu_houkoku v-if="form == 'shoubaikanri_jyutyu_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" /> -->
      <shuukin_houkoku v-if="form == 'shuukin_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <sukesan_kadou_nittei_houkoku v-if="form == 'sukesan_kadou_nittei_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <!-- <template_seisaku_irai v-if="form == 'template_seisaku_irai'" :kokyaku_code="kokyaku_code" @commit="onFormClose" /> -->
      <tsuika_shuzai_kanryou_houkoku v-if="form == 'tsuika_shuzai_kanryou_houkoku'" :kokyaku_code="kokyaku_code" @commit="onFormClose" />
      <!-- <yadosagashi_com_moushikomi_houkoku
        v-if="form == 'yadosagashi_com_moushikomi_houkoku'"
        :kokyaku_code="kokyaku_code"
        @commit="onFormClose"
      /> -->

      <v-card-actions>
        <v-btn color="warning" text @click="onFormClose">閉じる</v-btn>
      </v-card-actions>
    </v-card>

    <!-- <router-view :label="$route.meta.title" :key="$route.path"></router-view> -->
  </div>
</template>
<script>
// import apo_houkoku from "@/views/forms/apo_houkoku";
import cloud_kadou_houkoku from '@/views/forms/cloud_kadou_houkoku';
import dashboard from '@/views/forms/dashboard';
// import demo_houkoku from "@/views/forms/demo_houkoku";
import dounyuu_nittei_houkoku from '@/views/forms/dounyuu_nittei_houkoku';
import dounyuu_shuzai_kanryou_houkoku from '@/views/forms/dounyuu_shuzai_kanryou_houkoku';
import FacilityCreate from '@/components//facilities/Create';
import FacilityEdit from '@/components/facilities/Edit';
import irai_uketsuke_houkoku from '@/views/forms/irai_uketsuke_houkoku';
// import jyutyu_houkoku from "@/views/forms/jyutyu_houkoku";
// import kakunin_houkoku from "@/views/forms/kakunin_houkoku";
// import keijiban_zenin_haishin from '@/views/forms/keijiban_zenin_haishin';
// import kousei_houkoku from '@/views/forms/kousei_houkoku';
// import lease_kaishi from '@/views/forms/lease_kaishi';
// import lease_kaishuu from '@/views/forms/lease_kaishuu';
// import lease_sinsa_kekka_touroku from '@/views/forms/lease_sinsa_kekka_touroku';
// import lease_sinsa_teishutu from '@/views/forms/lease_sinsa_teishutu';
// import lease_teishutsu from '@/views/forms/lease_teishutsu';
import noKSRelation from '@/views/forms/noKSRelation';
// import nyuukin_kaishi from '@/views/forms/nyuukin_kaishi';
import service_kadou_houkoku from '@/views/forms/service_kadou_houkoku';
import service_shuryou_houkoku from '@/views/forms/service_shuryou_houkoku';
// import shoubaikanri_jyutyu_houkoku from '@/views/forms/shoubaikanri_jyutyu_houkoku';
import shuukin_houkoku from '@/views/forms/shuukin_houkoku';
import sukesan_kadou_nittei_houkoku from '@/views/forms/sukesan_kadou_nittei_houkoku';
// import template_seisaku_irai from '@/views/forms/template_seisaku_irai';
import tsuika_shuzai_kanryou_houkoku from '@/views/forms/tsuika_shuzai_kanryou_houkoku';
// import yadosagashi_com_moushikomi_houkoku from '@/views/forms/yadosagashi_com_moushikomi_houkoku';

export default {
  components: {
    // apo_houkoku,
    cloud_kadou_houkoku,
    dashboard,
    // demo_houkoku,
    dounyuu_nittei_houkoku,
    dounyuu_shuzai_kanryou_houkoku,
    FacilityCreate,
    FacilityEdit,
    irai_uketsuke_houkoku,
    // jyutyu_houkoku,
    // kakunin_houkoku,
    // keijiban_zenin_haishin,
    // kousei_houkoku,
    // lease_kaishi,
    // lease_kaishuu,
    // lease_sinsa_kekka_touroku,
    // lease_sinsa_teishutu,
    // lease_teishutsu,
    noKSRelation,
    // nyuukin_kaishi,
    service_kadou_houkoku,
    service_shuryou_houkoku,
    // shoubaikanri_jyutyu_houkoku,
    shuukin_houkoku,
    sukesan_kadou_nittei_houkoku,
    // template_seisaku_irai,
    tsuika_shuzai_kanryou_houkoku,
    // yadosagashi_com_moushikomi_houkoku,
  },
  props: {},
  data: () => ({}),
  computed: {
    form() {
      return this.$route.params.form;
    },
    kokyaku_code() {
      return this.$route.params.kokyaku_code;
    },
  },

  methods: {
    onFormClose() {
      this.$router.push({ name: 'Forms', params: { form: 'dashboard' } });
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>