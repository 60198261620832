<template>
  <v-card>
    <v-card-title>物件追加</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title>情報</v-card-title>

            <v-card-text>
              <validation-provider v-slot="{ errors }" name="見出し" rules="max:100">
                <v-text-field label="見出し" v-model="creator.title" counter :maxlength="100" :error-messages="errors" outlined dense />
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="物件名" rules="max:100">
                <v-text-field label="物件名" v-model="creator.name" counter :maxlength="100" :error-messages="errors" outlined dense />
              </validation-provider>

              <v-divider />
              <validation-provider v-slot="{ errors }" name="電話番号" rules="max:50">
                <v-text-field label="電話番号" v-model="creator.tel" counter :maxlength="50" :error-messages="errors" outlined dense />
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="郵便番号" rules="max:7">
                <v-text-field
                  label="郵便番号"
                  v-model="creator.postalCode"
                  counter
                  :maxlength="7"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="都道府県" rules="">
                <v-select
                  v-model="creator.prefCode"
                  :items="dataContext.prefs"
                  label="*都道府県"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  required
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="住所1" rules="max:100">
                <v-text-field label="住所1" v-model="creator.address1" counter :maxlength="100" :error-messages="errors" outlined dense />
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="住所2" rules="max:100">
                <v-text-field label="住所2" v-model="creator.address2" counter :maxlength="100" :error-messages="errors" outlined dense />
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                <v-textarea label="備考" v-model="creator.memo" counter :maxlength="1000" :error-messages="errors" outlined dense />
              </validation-provider>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col col="4">
          <v-card>
            <v-card-title>情報</v-card-title>
            <v-card-text>
              <v-row align="center">
                <v-col>
                  <validation-provider v-slot="{ errors }" name="" rules="">
                    <v-chip-group
                      v-model="creator.hotelTypes"
                      active-class="deep-purple accent-4 white--text"
                      :error-messages="errors"
                      column
                      multiple
                      dense
                    >
                      <v-chip :value="1" small>ホテル</v-chip>
                      <v-chip :value="1 << 1" small>旅館</v-chip>
                      <v-chip :value="1 << 2" small>民宿</v-chip>
                      <v-chip :value="1 << 3" small>ペンション</v-chip>

                      <v-chip :value="1 << 9" small>ダミー</v-chip>
                    </v-chip-group>
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider v-slot="{ errors }" name="定員" rules="">
                    <v-text-field label="定員" v-model.number="creator.capacity" counter :maxlength="100" :error-messages="errors" dense />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col>
                  <validation-provider v-slot="{ errors }" name="支店" rules="">
                    <v-combobox
                      label="支店"
                      item-text="name"
                      item-value="id"
                      :items="dataContext.shitens"
                      v-model="creator.branchs"
                      :error-messages="errors"
                      multiple
                      dense
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider v-slot="{ errors }" name="担当" rules="">
                    <v-combobox
                      label="担当"
                      item-text="name"
                      item-value="id"
                      :items="dataContext.staffs"
                      v-model="creator.employees"
                      :error-messages="errors"
                      multiple
                      dense
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider v-slot="{ errors }" name="lat" rules="max:100">
                    <v-text-field label="lat" v-model="creator.lat" counter :maxlength="100" :error-messages="errors" dense />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider v-slot="{ errors }" name="lng" rules="max:100">
                    <v-text-field label="lng" v-model="creator.lng" counter :maxlength="100" :error-messages="errors" dense />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider v-slot="{ errors }" name="楽天ホテルID" rules="max:100">
                    <v-text-field label="楽天ホテルID" v-model="creator.hotelNo" counter :maxlength="100" :error-messages="errors" dense />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider v-slot="{ errors }" name="ナレッジID" rules="max:100">
                    <v-text-field label="ナレッジID" v-model="creator.ksNo" counter :maxlength="100" :error-messages="errors" dense />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="warning" @click="onCancelcreatorClicked"> 閉じる </v-btn>

      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitcreatorClicked"> 追加 </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    item: {
      type: Object,
      default: () => {},
    },

    label: {
      type: String,
      default: () => '施設情報追加',
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },
  data: () => ({
    initializing: true,
    /** */
    creator: {
      loading: false,

      title: null,
      subtitle: null,
      name: null,
      lat: null,
      lng: null,
      postalCode: null,
      prefCode: null,
      address1: null,
      address2: null,
      tel: null,
      faxNo: null,
      email: null,
      url: null,
      capacity: 0,
      memo: null,
      tags: [],
      avatar: { src: null },
      employees: [],
      branchs: [],
      hotelTypes: [],
      infos: [],

      hotelNo: null,
      ksNo: null,
    },
  }),
  methods: {
    onLoaded() {
      this.creator.name = this.item.name;
      this.creator.hotelNo = this.item.hotelNo;
      this.creator.postalCode = this.item.postalCode;
      this.creator.prefCode = this.item.prefCode;
      this.creator.address1 = this.item.address1;
      this.creator.address2 = this.item.address2;
      this.creator.tel = this.item.tel;
      this.creator.faxNo = this.item.faxNo;
      this.creator.url = this.item.url;
      this.creator.lat = this.item.lat;
      this.creator.lng = this.item.lng;
      this.creator.avatar.src = this.item.avatar.src;
    },

    onCancelcreatorClicked() {
      //
      this.$emit('cancel');
    },
    async onCommitcreatorClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //
      let request = {
        title: this.creator.title,
        subtitle: this.creator.subtitle,
        name: this.creator.name,
        lat: this.creator.lat,
        lng: this.creator.lng,
        postalCode: this.creator.postalCode,
        prefCode: this.creator.prefCode,
        address1: this.creator.address1,
        address2: this.creator.address2,
        tel: this.creator.tel,
        faxNo: this.creator.faxNo,
        email: this.creator.email,
        url: this.creator.url,
        capacity: this.creator.capacity,
        memo: this.creator.memo,
        tags: this.creator.tags,
        employees: this.creator.employees.map((a) => a.id),
        branchs: this.creator.branchs.map((a) => a.id),
        hotelTypes: this.creator.hotelTypes,
        hotelNo: this.creator.hotelNo,
        ksNo: this.creator.ksNo,
      };
      let file = this.creator.avatar.file;
      //

      // nullable
      request.capacity = this.convertNumber(request.capacity);

      this.postUpload('facility', request, [file])
        .then((success) => {
          let data = success.data;
          //
          this.$emit('commit', data);
          this.$emit('update:lat', this.creator.lat);
          this.$emit('update:lng', this.creator.lng);

          this.showSuccess('施設情報を追加しました');
        })
        .catch(() => {
          this.showError('施設情報追加中にエラーが発生しました');
        });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>