<template>
  <v-card flat>
    <v-card-title>
      <v-btn color="warning" text @click="onCancelClicked"> 閉じる </v-btn>
      <v-spacer></v-spacer>
      {{ label }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onSubmitClicked">
        <v-icon left>mdi-email-send</v-icon>
        送信する
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <div class="mb-4 text-caption">(*)は必須</div>

        <validation-provider v-slot="{ errors }" name="報告者" rules="required">
          <v-select
            v-model="creator.houkokusha"
            :items="dataContext.staffs"
            label="*報告者"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '報告者 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
          <v-select
            v-model="creator.pref"
            :items="dataContext.prefs"
            label="*都道府県"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '都道府県 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="地域" rules="max:100">
          <v-text-field v-model="creator.area" label="地域" counter :maxlength="100" :error-messages="errors" clearable dense />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
          <v-combobox
            v-model="creator.kokyaku_name"
            :items="getKokyakus(creator)"
            label="*顧客名"
            :prepend-inner-icon="!isEmpty(creator.kokyaku_code) ? `mdi-check` : ``"
            :rules="[(v) => !!v || '顧客名 は必須です']"
            counter
            :maxlength="100"
            :error-messages="errors"
            clearable
            required
            dense
            @change="onKokyakuNameChanged(creator)"
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="プラン" rules="required">
          <v-select
            v-model="creator.plan"
            :items="dataContext.plans"
            label="*プラン"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'プラン は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="担当者" rules="">
          <v-select
            v-model="creator.tantousha"
            :items="dataContext.staffs"
            label="担当者"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="サービス終了予定日" rules="">
          <DateTime label="サービス終了予定日" v-model="creator.service_shuuryou_yoteibi" :error-messages="errors" />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
          <v-textarea v-model="creator.bikou" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
        </validation-provider>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },

    facilityCode: {
      type: String,
    },

    label: {
      type: String,
      default: () => 'サービス終了報告',
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },

    kokyaku_code() {
      if (this.facilityCode != null) return this.facilityCode;
      return this.$route.params.kokyaku_code;
    },
  },
  data: () => ({
    initializing: true,

    creator: {
      loading: false,

      houkokusha: null,
      pref: null,
      area: null,
      kokyaku_code: null,
      kokyaku_name: null,
      plan: null,
      tantousha: null,
      service_shuuryou_yoteibi: null,
      bikou: null,
    },
  }),
  methods: {
    onLoaded() {
      this.initializing = true;
      this.setKokyakuData(this.creator, this.kokyaku_code);

      this.initializing = false;
    },
    /**
     * フォームクリアイベント
     */
    onClearClicked() {
      this.form.reset();
    },
    /**
     * フォーム決定イベント
     */
    async onSubmitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      //
      let request = this.creator;
      request.service_shuuryou_yoteibi = this.convertDate(this.creator.service_shuuryou_yoteibi);

      //
      this.post('form/service_shuryou_houkoku', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit');
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    /** フォーム変更監視 */
    creator: {
      handler: function () {
        this.setFormChanged(true);
      },
      deep: true,
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>