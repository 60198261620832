var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("物件追加")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',[_c('v-card-title',[_vm._v("情報")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"見出し","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"見出し","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.title),callback:function ($$v) {_vm.$set(_vm.creator, "title", $$v)},expression:"creator.title"}})]}}])}),_c('validation-provider',{attrs:{"name":"物件名","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"物件名","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.name),callback:function ($$v) {_vm.$set(_vm.creator, "name", $$v)},expression:"creator.name"}})]}}])}),_c('v-divider'),_c('validation-provider',{attrs:{"name":"電話番号","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"電話番号","counter":"","maxlength":50,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.tel),callback:function ($$v) {_vm.$set(_vm.creator, "tel", $$v)},expression:"creator.tel"}})]}}])}),_c('validation-provider',{attrs:{"name":"郵便番号","rules":"max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"郵便番号","counter":"","maxlength":7,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.postalCode),callback:function ($$v) {_vm.$set(_vm.creator, "postalCode", $$v)},expression:"creator.postalCode"}})]}}])}),_c('validation-provider',{attrs:{"name":"都道府県","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.prefs,"label":"*都道府県","item-text":"name","item-value":"id","error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.prefCode),callback:function ($$v) {_vm.$set(_vm.creator, "prefCode", $$v)},expression:"creator.prefCode"}})]}}])}),_c('validation-provider',{attrs:{"name":"住所1","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"住所1","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.address1),callback:function ($$v) {_vm.$set(_vm.creator, "address1", $$v)},expression:"creator.address1"}})]}}])}),_c('validation-provider',{attrs:{"name":"住所2","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"住所2","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.address2),callback:function ($$v) {_vm.$set(_vm.creator, "address2", $$v)},expression:"creator.address2"}})]}}])}),_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.memo),callback:function ($$v) {_vm.$set(_vm.creator, "memo", $$v)},expression:"creator.memo"}})]}}])})],1)],1)],1),_c('v-col',{attrs:{"col":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v("情報")]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text","error-messages":errors,"column":"","multiple":"","dense":""},model:{value:(_vm.creator.hotelTypes),callback:function ($$v) {_vm.$set(_vm.creator, "hotelTypes", $$v)},expression:"creator.hotelTypes"}},[_c('v-chip',{attrs:{"value":1,"small":""}},[_vm._v("ホテル")]),_c('v-chip',{attrs:{"value":1 << 1,"small":""}},[_vm._v("旅館")]),_c('v-chip',{attrs:{"value":1 << 2,"small":""}},[_vm._v("民宿")]),_c('v-chip',{attrs:{"value":1 << 3,"small":""}},[_vm._v("ペンション")]),_c('v-chip',{attrs:{"value":1 << 9,"small":""}},[_vm._v("ダミー")])],1)]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"定員","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"定員","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.creator.capacity),callback:function ($$v) {_vm.$set(_vm.creator, "capacity", _vm._n($$v))},expression:"creator.capacity"}})]}}])})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"支店","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"支店","item-text":"name","item-value":"id","items":_vm.dataContext.shitens,"error-messages":errors,"multiple":"","dense":""},model:{value:(_vm.creator.branchs),callback:function ($$v) {_vm.$set(_vm.creator, "branchs", $$v)},expression:"creator.branchs"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"担当","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"担当","item-text":"name","item-value":"id","items":_vm.dataContext.staffs,"error-messages":errors,"multiple":"","dense":""},model:{value:(_vm.creator.employees),callback:function ($$v) {_vm.$set(_vm.creator, "employees", $$v)},expression:"creator.employees"}})]}}])})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"lat","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"lat","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.creator.lat),callback:function ($$v) {_vm.$set(_vm.creator, "lat", $$v)},expression:"creator.lat"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"lng","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"lng","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.creator.lng),callback:function ($$v) {_vm.$set(_vm.creator, "lng", $$v)},expression:"creator.lng"}})]}}])})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"楽天ホテルID","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"楽天ホテルID","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.creator.hotelNo),callback:function ($$v) {_vm.$set(_vm.creator, "hotelNo", $$v)},expression:"creator.hotelNo"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"ナレッジID","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ナレッジID","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.creator.ksNo),callback:function ($$v) {_vm.$set(_vm.creator, "ksNo", $$v)},expression:"creator.ksNo"}})]}}])})],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":_vm.onCancelcreatorClicked}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommitcreatorClicked}},[_vm._v(" 追加 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }