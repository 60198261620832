var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.onCancelClicked}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onSubmitClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-send")]),_vm._v(" 送信する ")],1)],1),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('v-form',{ref:"form"},[_c('div',{staticClass:"mb-4 text-caption"},[_vm._v("(*)は必須")]),_c('validation-provider',{attrs:{"name":"集金者","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.staffs,"label":"*集金者","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '集金者 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.shuukinsha),callback:function ($$v) {_vm.$set(_vm.creator, "shuukinsha", $$v)},expression:"creator.shuukinsha"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"顧客名","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.getKokyakus(_vm.creator),"label":"*顧客名","prepend-inner-icon":!_vm.isEmpty(_vm.creator.kokyaku_code) ? "mdi-check" : "","rules":[function (v) { return !!v || '顧客名 は必須です'; }],"counter":"","maxlength":100,"error-messages":errors,"clearable":"","required":"","dense":""},on:{"change":function($event){return _vm.onKokyakuNameChanged(_vm.creator)}},model:{value:(_vm.creator.kokyaku_name),callback:function ($$v) {_vm.$set(_vm.creator, "kokyaku_name", $$v)},expression:"creator.kokyaku_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"集金日","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"*集金日","rules":[function (v) { return !!v || '集金日 は必須です'; }],"error-messages":errors},model:{value:(_vm.creator.shuukin_bi),callback:function ($$v) {_vm.$set(_vm.creator, "shuukin_bi", $$v)},expression:"creator.shuukin_bi"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"金額","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"金額","clearable":"","dense":"","suffix":"円","counter":"","maxlength":10,"error-messages":errors},model:{value:(_vm.creator.shuukin_kingaku),callback:function ($$v) {_vm.$set(_vm.creator, "shuukin_kingaku", _vm._n($$v))},expression:"creator.shuukin_kingaku"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"備考","hint":"集金内容、方法を記載して下さい。","counter":"","maxlength":1000,"error-messages":errors,"clearable":""},model:{value:(_vm.creator.bikou),callback:function ($$v) {_vm.$set(_vm.creator, "bikou", $$v)},expression:"creator.bikou"}})]}}],null,true)})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }