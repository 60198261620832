<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> ■ 社内管理関係 </v-list-item-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://www.liberty-web.com/stuff/nbbs_post.php" target="_blank">・社内掲示板(PC版)</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://libertymarket.jp/stuffj/bbspost.php" target="_blank">・社内掲示板(携帯投稿)</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> ■ 社内報告関連 </v-list-item-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://www.liberty-web.com/stuffj/houkoku/" target="_blank">・導入取材報告フォーム</v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://www.liberty-web.com/stuffj/tuika/" target="_blank">・追加取材報告フォーム</v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://www.liberty-web.com/stuffj/kousei/" target="_blank">・構成報告フォーム</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> ■ 『宿ぷらす+』関連 </v-list-item-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://liberty-web.com/yadoplus/quotation.html" target="_blank">・見積依頼フォーム </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://liberty-web.com/yadoplus/sample.html" target="_blank">・アメニティサンプル送付依頼フォーム </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> ■ WEB関係 </v-list-item-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-title>
              <v-btn text href="http://fix.liberty-hp.com/input" target="_blank">・HP修正依頼 </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-col md="3">
        <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          ■宿さがし.com関係フォーム
        </v-list-item-title>
        <v-list-item-subtitle> 宿さがし掲載関連で使用します。 </v-list-item-subtitle>
        <v-list-item-title><v-btn text  to="yadosagashi_com_moushikomi_houkoku">・宿さがし.com申込報告</v-btn></v-list-item-title>
        <v-list-item-title><v-btn text  to="template_seisaku_irai" class="text-decoration-line-through" disabled>・テンプレート制作依頼</v-btn></v-list-item-title>
      </v-list-item-content>
    </v-list-item>

      </v-col> -->

    <!--     

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          □社内掲示板(投稿用)
        </v-list-item-title>
        <v-list-item-subtitle> 汎用的な全社発信に使用します。 </v-list-item-subtitle>
        <v-list-item-title><v-btn text  to="keijiban_zenin_haishin" >・掲示板(全員)配信</v-btn></v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="sukesanKadouNitteiHoukoku.show" inset>
        <SukesanKadouNitteiHoukoku
          :observer="observer"
          @commit="sukesanKadouNitteiHoukoku.show = false"
          @cancel="sukesanKadouNitteiHoukoku.show = false"
        />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="dounyuuNitteiHoukoku.show" inset>
        <DounyuuNitteiHoukoku
          :observer="observer"
          @commit="dounyuuNitteiHoukoku.show = false"
          @cancel="dounyuuNitteiHoukoku.show = false"
        />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="dounyuuShuzaiKanryouHoukoku.show" inset>
        <DounyuuShuzaiKanryouHoukoku
          :observer="observer"
          @commit="dounyuuShuzaiKanryouHoukoku.show = false"
          @cancel="dounyuuShuzaiKanryouHoukoku.show = false"
        />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="sukesanKadouNitteiHoukoku.show" inset>
        <SukesanKadouNitteiHoukoku
          :observer="observer"
          @commit="sukesanKadouNitteiHoukoku.show = false"
          @cancel="sukesanKadouNitteiHoukoku.show = false"
        />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="kouseiHoukoku.show" inset>
        <KouseiHoukoku :observer="observer" @commit="kouseiHoukoku.show = false" @cancel="kouseiHoukoku.show = false" />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="tsuikaShuzaiKanryouHoukoku.show" inset>
        <TsuikaShuzaiKanryouHoukoku
          :observer="observer"
          @commit="tsuikaShuzaiKanryouHoukoku.show = false"
          @cancel="tsuikaShuzaiKanryouHoukoku.show = false"
        />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="serviceKadouHoukoku.show" inset>
        <ServiceKadouHoukoku :observer="observer" @commit="serviceKadouHoukoku.show = false" @cancel="serviceKadouHoukoku.show = false" />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="cloudKadouHoukoku.show" inset>
        <CloudKadouHoukoku :observer="observer" @commit="cloudKadouHoukoku.show = false" @cancel="cloudKadouHoukoku.show = false" />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="serviceShuryouHoukoku.show" inset>
        <ServiceShuryouHoukoku
          :observer="observer"
          @commit="serviceShuryouHoukoku.show = false"
          @cancel="serviceShuryouHoukoku.show = false"
        />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="iraiUketsukeHoukoku.show" inset>
        <IraiUketsukeHoukoku :observer="observer" @commit="iraiUketsukeHoukoku.show = false" @cancel="iraiUketsukeHoukoku.show = false" />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="shuukinHoukoku.show" inset>
        <ShuukinHoukoku :observer="observer" @commit="shuukinHoukoku.show = false" @cancel="shuukinHoukoku.show = false" />
      </v-bottom-sheet>
    </ValidationObserver>
  </v-list>
</template>

<script>
import DounyuuNitteiHoukoku from './dounyuu_nittei_houkoku';
import DounyuuShuzaiKanryouHoukoku from './dounyuu_shuzai_kanryou_houkoku';
import KouseiHoukoku from './kousei_houkoku';
import CloudKadouHoukoku from './cloud_kadou_houkoku';
import IraiUketsukeHoukoku from './irai_uketsuke_houkoku';
import ServiceKadouHoukoku from './service_kadou_houkoku';
import ServiceShuryouHoukoku from './service_shuryou_houkoku';
import ShuukinHoukoku from './shuukin_houkoku';
import SukesanKadouNitteiHoukoku from './sukesan_kadou_nittei_houkoku';
import TsuikaShuzaiKanryouHoukoku from './tsuika_shuzai_kanryou_houkoku';

export default {
  components: {
    SukesanKadouNitteiHoukoku,
    DounyuuNitteiHoukoku,
    DounyuuShuzaiKanryouHoukoku,
    KouseiHoukoku,
    TsuikaShuzaiKanryouHoukoku,
    ServiceKadouHoukoku,
    CloudKadouHoukoku,
    ServiceShuryouHoukoku,
    IraiUketsukeHoukoku,
    ShuukinHoukoku,
  },

  data: () => ({
    sukesanKadouNitteiHoukoku: {
      show: false,
    },
    dounyuuNitteiHoukoku: {
      show: false,
    },
    dounyuuShuzaiKanryouHoukoku: {
      show: false,
    },
    kouseiHoukoku: {
      show: false,
    },
    tsuikaShuzaiKanryouHoukoku: {
      show: false,
    },
    serviceKadouHoukoku: {
      show: false,
    },
    cloudKadouHoukoku: {
      show: false,
    },
    serviceShuryouHoukoku: {
      show: false,
    },
    iraiUketsukeHoukoku: {
      show: false,
    },
    shuukinHoukoku: {
      show: false,
    },
  }),
  methods: {},
};
</script>
