var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.onCancelClicked}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onSubmitClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-send")]),_vm._v(" 送信する ")],1)],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('div',{staticClass:"mb-4 text-caption"},[_vm._v("(*)は必須")]),_c('validation-provider',{attrs:{"name":"報告者","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.staffs,"label":"*報告者","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '報告者 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.houkokusha),callback:function ($$v) {_vm.$set(_vm.creator, "houkokusha", $$v)},expression:"creator.houkokusha"}})]}}])}),_c('validation-provider',{attrs:{"name":"支店","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.shitens,"label":"*支店","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '支店 は必須です'; }],"error-messages":errors,"required":"","dense":""},model:{value:(_vm.creator.shiten),callback:function ($$v) {_vm.$set(_vm.creator, "shiten", $$v)},expression:"creator.shiten"}})]}}])}),_c('validation-provider',{attrs:{"name":"都道府県","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.prefs,"label":"*都道府県","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '都道府県 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.pref),callback:function ($$v) {_vm.$set(_vm.creator, "pref", $$v)},expression:"creator.pref"}})]}}])}),_c('validation-provider',{attrs:{"name":"地域","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"地域","counter":"","maxlength":100,"error-messages":errors,"clearable":"","dense":""},model:{value:(_vm.creator.area),callback:function ($$v) {_vm.$set(_vm.creator, "area", $$v)},expression:"creator.area"}})]}}])}),_c('validation-provider',{attrs:{"name":"顧客名","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.getKokyakus(_vm.creator),"label":"*顧客名","prepend-inner-icon":!_vm.isEmpty(_vm.creator.kokyaku_code) ? "mdi-check" : "","rules":[function (v) { return !!v || '顧客名 は必須です'; }],"counter":"","maxlength":100,"error-messages":errors,"clearable":"","required":"","dense":""},on:{"change":function($event){return _vm.onKokyakuNameChanged(_vm.creator)}},model:{value:(_vm.creator.kokyaku_name),callback:function ($$v) {_vm.$set(_vm.creator, "kokyaku_name", $$v)},expression:"creator.kokyaku_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"あすなろ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.asunaro_types,"label":"*あすなろ","item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'あすなろ は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.asunaro_type),callback:function ($$v) {_vm.$set(_vm.creator, "asunaro_type", $$v)},expression:"creator.asunaro_type"}})]}}])}),_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"すけさん・かくさん"}},[_c('validation-provider',{attrs:{"name":"すけさん・かくさん","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.creator.sukesan_kakusan),callback:function ($$v) {_vm.$set(_vm.creator, "sukesan_kakusan", $$v)},expression:"creator.sukesan_kakusan"}},_vm._l((_vm.dataContext.sukesan_kakusans),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)]}}])})],1),_c('validation-provider',{attrs:{"name":"稼働日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"*稼働日","rules":[function (v) { return !!v || '稼働日 は必須です'; }],"error-messages":errors},model:{value:(_vm.creator.kadoubi),callback:function ($$v) {_vm.$set(_vm.creator, "kadoubi", $$v)},expression:"creator.kadoubi"}})]}}])}),_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.creator.bikou),callback:function ($$v) {_vm.$set(_vm.creator, "bikou", $$v)},expression:"creator.bikou"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }