 <template>
  <v-card>
    <v-card-title>{{ label }}</v-card-title>
    <v-card-title></v-card-title>
    <v-card-subtitle>顧客情報とナレッジIDを紐づけます。</v-card-subtitle>

    <v-card-text>
      <v-card-title>施設名</v-card-title>
      <v-card-text v-text="editor.name"></v-card-text>

      <v-card-title>ナレッジID</v-card-title>
      <v-card-text>
        <validation-provider v-slot="{ errors }" name="ナレッジID" rules="max:100">
          <v-text-field
            v-model="editor.ksNo"
            :error="result.ksNoError"
            :messages="result.ksNoMessage"
            :prepend-inner-icon="!isEmpty(result.ksNoOK) ? `mdi-check` : ``"
            @input="onKSNoChanged"
            counter
            :maxlength="100"
            :error-messages="errors"
            dense
          />
        </validation-provider>
      </v-card-text>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn text color="warning" @click="onCancelClicked"> 閉じる </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitClicked"> 決定 </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
export default {
  model: {
    prop: 'show',
    event: 'change',
  },

  props: {
    show: {
      type: Boolean,
    },

    observer: {
      type: Object,
      required: true,
    },

    label: {
      type: String,
      default: () => 'ナレッジ紐づけ',
    },

    facilityCode: {
      type: String,
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    kokyaku_code() {
      if (this.facilityCode != null) return this.facilityCode;
      return this.$route.params.kokyaku_code;
    },

    inputedValue: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
  },

  data: () => ({
    initializing: true,

    // getで得たものをそのまま入れる
    editor: {},

    result: {
      ksNoOK: false,
      ksNoError: false,
      ksNoMessage: null,
    },
  }),

  methods: {
    onLoaded() {
      this.get('facility', { facilityCode: this.kokyaku_code }).then((success) => {
        let data = success.data;
        //
        this.editor = data;
        //
        this.result.ksNoOK = false;
        this.result.ksNoError = false;
        this.result.ksNoMessage = ``;
      });
    },

    onKSNoChanged(val) {
      this.get('ksCustomer', { ksCustomerCode: val }).then((success) => {
        if (success.error) {
          this.result.ksNoOK = false;
          this.result.ksNoError = true;
          this.result.ksNoMessage = '対象のデータがありません';
        } else {
          let data = success.data;
          this.result.ksNoOK = true;
          this.result.ksNoError = false;
          this.result.ksNoMessage = `:${data.code} :${data.name}`;
        }
      });
    },

    async onCommitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      // getで得たものをそのまま入れたのでそのまま返す（フォームにない項目も含まれている)
      let request = this.editor;
      (request.facilityCode = this.facilityCode),
        this.putUpload('facility', request, []).then(() => {
          this.$emit('commit');
          //
        });
    },
    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  created() {
    // this.onLoaded();
  },
};
</script>