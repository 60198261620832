
<template>
  <v-card flat>
    <v-card-title>
      <v-btn color="warning" text @click="onCancelClicked"> 閉じる </v-btn>
      <v-spacer />
      {{ label }}
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onSubmitClicked">
        <v-icon left>mdi-email-send</v-icon>
        送信する
      </v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <v-form v-if="loaded" ref="form">
            <div class="mb-4 text-caption">(*)は必須</div>

            <validation-provider v-slot="{ errors }" name="報告者" rules="required">
              <v-select
                v-model="creator.houkokusha"
                :items="dataContext.staffs"
                label="*報告者"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || '報告者 は必須です']"
                :error-messages="errors"
                required
                outlined
                dense
              />
            </validation-provider>

            <validation-provider v-slot="{ errors }" name="顧客名" rules="required">
              <v-combobox
                v-model="creator.kokyaku_name"
                :items="getKokyakus(creator)"
                label="*顧客名"
                :prepend-inner-icon="!isEmpty(creator.kokyaku_code) ? `mdi-check` : ``"
                :rules="[(v) => !!v || '顧客名 は必須です']"
                counter
                :maxlength="100"
                :error-messages="errors"
                clearable
                required
                dense
                @change="onKokyakuNameChanged(creator)"
              />
            </validation-provider>

            <XFieldSet class="mb-4" label="デモ予定日時">
              <validation-provider v-slot="{ errors }" name="デモ予定日時" rules="">
                <DateTime label="デモ予定日時" v-model="creator.kadou_yoteibi" :error-messages="errors">
                  <template v-slot="{ click }"
                    ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ creator.kadou_yoteibi | dateTime }} </v-btn>
                  </template>
                </DateTime>
              </validation-provider>
            </XFieldSet>

            <validation-provider v-slot="{ errors }" name="最低保証" rules="">
              <v-text-field
                type="number"
                v-model.number="creator.saiteihoshou"
                label="最低保証"
                counter
                :maxlength="10"
                :error-messages="errors"
                clearable
                dense
                suffix="円"
              />
            </validation-provider>

            <XFieldSet class="mb-4" label="*初期費用回収">
              <validation-provider v-slot="{ errors }" name="初期費用回収" rules="required">
                <v-radio-group
                  v-model="creator.shoki_hiyou"
                  row
                  :rules="[(v) => !!v || '事前審査結果 は必須です']"
                  :error-messages="errors"
                >
                  <v-radio v-for="(item, index) in dataContext.shoki_hiyous" :key="index" :label="item.name" :value="item.id"></v-radio>
                </v-radio-group>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="回収予定日" rules="required">
                <DateTime label="*回収予定日" v-model="creator.kaishuuyoteibi" :error-messages="errors" />
              </validation-provider>
            </XFieldSet>

            <XFieldSet class="mb-4" label="*提出書提出済み">
              <validation-provider v-slot="{ errors }" name="提出書提出済み" rules="required">
                <v-radio-group v-model="creator.lease_teishutsu" row :error-messages="errors">
                  <v-radio v-for="(item, index) in dataContext.lease_teishutsus" :key="index" :label="item.name" :value="item.id"></v-radio>
                </v-radio-group>
              </validation-provider>

              <validation-provider v-slot="{ errors }" name="提出予定日" rules="required">
                <DateTime v-model="creator.teishutsuyoteibi" label="*提出予定日" :error-messages="errors" />
              </validation-provider>
            </XFieldSet>

            <XFieldSet class="mb-4" label="*手請求か？">
              <validation-provider v-slot="{ errors }" name="手請求か？" rules="required">
                <v-checkbox label="手請求" v-model="creator.teseikyuu" :error-messages="errors" />
              </validation-provider>
            </XFieldSet>

            <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
              <v-textarea v-model="creator.bikou" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
            </validation-provider>
          </v-form>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },

    facilityCode: {
      type: String,
    },

    label: {
      type: String,
      default: () => 'すけさん稼働予定日報告',
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    kokyaku_code() {
      if (this.facilityCode != null) return this.facilityCode;
      return this.$route.params.kokyaku_code;
    },
  },
  data: () => ({
    initializing: true,

    kokyakus: [],

    creator: {
      loading: false,

      houkokusha: null,
      kokyaku_code: null,
      kokyaku_name: null,
      kadou_yoteibi: new Date(),

      saiteihoshou: null,

      shoki_hiyou: '1',
      kaishuuyoteibi: null,

      lease_teishutsu: '1',
      teishutsuyoteibi: null,

      teseikyuu: false,

      bikou: null,
    },
  }),
  methods: {
    onLoaded() {
      this.initializing = true;
      this.setKokyakuData(this.creator, this.kokyaku_code);

      this.initializing = false;
    },

    /**
     * フォームクリアイベント
     */
    onClearClicked() {
      this.form.reset();
    },
    /**
     * フォーム決定イベント
     */
    async onSubmitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('送信してよろしいですか？')) return;
      //
      //
      let request = this.creator;
      request.kadou_yoteibi = this.convertDate(this.creator.kadou_yoteibi);
      request.kaishuuyoteibi = this.convertDate(this.creator.kaishuuyoteibi);
      request.teishutsuyoteibi = this.convertDate(this.creator.teishutsuyoteibi);

      //
      this.post('form/sukesan_kadou_nittei_houkoku', this.creator)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit');
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    /** フォーム変更監視 */
    creator: {
      handler: function () {
        this.setFormChanged(true);
      },
      deep: true,
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>