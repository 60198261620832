var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.onCancelClicked}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onSubmitClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-send")]),_vm._v(" 送信する ")],1)],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('div',{staticClass:"mb-4 text-caption"},[_vm._v("(*)は必須")]),_c('validation-provider',{attrs:{"name":"報告者","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.staffs,"label":"*報告者","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '報告者 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.houkokusha),callback:function ($$v) {_vm.$set(_vm.creator, "houkokusha", $$v)},expression:"creator.houkokusha"}})]}}])}),_c('validation-provider',{attrs:{"name":"都道府県","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.prefs,"label":"*都道府県","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '都道府県 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.pref),callback:function ($$v) {_vm.$set(_vm.creator, "pref", $$v)},expression:"creator.pref"}})]}}])}),_c('validation-provider',{attrs:{"name":"地域","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"地域","counter":"","maxlength":100,"error-messages":errors,"clearable":"","dense":""},model:{value:(_vm.creator.area),callback:function ($$v) {_vm.$set(_vm.creator, "area", $$v)},expression:"creator.area"}})]}}])}),_c('validation-provider',{attrs:{"name":"顧客名","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.getKokyakus(_vm.creator),"label":"*顧客名","prepend-inner-icon":!_vm.isEmpty(_vm.creator.kokyaku_code) ? "mdi-check" : "","rules":[function (v) { return !!v || '顧客名 は必須です'; }],"counter":"","maxlength":100,"error-messages":errors,"clearable":"","required":"","dense":""},on:{"change":function($event){return _vm.onKokyakuNameChanged(_vm.creator)}},model:{value:(_vm.creator.kokyaku_name),callback:function ($$v) {_vm.$set(_vm.creator, "kokyaku_name", $$v)},expression:"creator.kokyaku_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"プラン","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.dounyuu_plans,"label":"*プラン","item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'プラン は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.dounyuu_plan),callback:function ($$v) {_vm.$set(_vm.creator, "dounyuu_plan", $$v)},expression:"creator.dounyuu_plan"}})]}}])}),_c('validation-provider',{attrs:{"name":"導入者","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.staffs,"label":"*導入者","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '導入者 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.dounyuusha),callback:function ($$v) {_vm.$set(_vm.creator, "dounyuusha", $$v)},expression:"creator.dounyuusha"}})]}}])}),_c('validation-provider',{attrs:{"name":"取材者","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.staffs,"label":"*取材者","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '取材者 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.shuzaisha),callback:function ($$v) {_vm.$set(_vm.creator, "shuzaisha", $$v)},expression:"creator.shuzaisha"}})]}}])}),_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"*リ―ス用紙"}},[_c('validation-provider',{attrs:{"name":"リ―ス用紙","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","rules":[function (v) { return !!v || 'リ―ス用紙 は必須です'; }],"error-messages":errors},model:{value:(_vm.creator.lease_youshi),callback:function ($$v) {_vm.$set(_vm.creator, "lease_youshi", $$v)},expression:"creator.lease_youshi"}},_vm._l((_vm.dataContext.lease_youshis),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)]}}])})],1),_c('validation-provider',{attrs:{"name":"提出待ち理由","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"提出待ち理由","counter":"","maxlength":1000,"error-messages":errors,"clearable":""},model:{value:(_vm.creator.lease_youshi_riyuu),callback:function ($$v) {_vm.$set(_vm.creator, "lease_youshi_riyuu", $$v)},expression:"creator.lease_youshi_riyuu"}})]}}])}),_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"ドメイン"}},[_c('validation-provider',{attrs:{"name":"ドメイン","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.creator.domain_shutoku),callback:function ($$v) {_vm.$set(_vm.creator, "domain_shutoku", $$v)},expression:"creator.domain_shutoku"}},_vm._l((_vm.dataContext.domain_shutokus),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)]}}])})],1),_c('validation-provider',{attrs:{"name":"ドメイン名","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ドメイン名","counter":"","maxlength":100,"error-messages":errors,"clearable":"","dense":""},model:{value:(_vm.creator.domain_name),callback:function ($$v) {_vm.$set(_vm.creator, "domain_name", $$v)},expression:"creator.domain_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"domain","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.domain_suffixies,"label":"domain","item-text":"name","item-value":"id","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.domain_suffix),callback:function ($$v) {_vm.$set(_vm.creator, "domain_suffix", $$v)},expression:"creator.domain_suffix"}})]}}])}),_c('validation-provider',{attrs:{"name":"取材時間","rules":"required|max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"*取材時間","rules":[function (v) { return !!v || '時間 は必須です'; }],"counter":"","maxlength":2,"error-messages":errors,"clearable":"","dense":"","suffix":"時間"},model:{value:(_vm.creator.shuzai_zikan),callback:function ($$v) {_vm.$set(_vm.creator, "shuzai_zikan", _vm._n($$v))},expression:"creator.shuzai_zikan"}})]}}])}),_c('validation-provider',{attrs:{"name":"構成担当","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.staffs,"label":"構成担当","item-text":"name","item-value":"id","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.creator.kousei_tantou),callback:function ($$v) {_vm.$set(_vm.creator, "kousei_tantou", $$v)},expression:"creator.kousei_tantou"}})]}}])}),_c('validation-provider',{attrs:{"name":"構成提出日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"構成提出日","error-messages":errors},model:{value:(_vm.creator.kousei_teishutubi),callback:function ($$v) {_vm.$set(_vm.creator, "kousei_teishutubi", $$v)},expression:"creator.kousei_teishutubi"}})]}}])}),_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"取材経費"}},[_c('validation-provider',{attrs:{"name":"取材経費","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.creator.shuzai_keihi),callback:function ($$v) {_vm.$set(_vm.creator, "shuzai_keihi", $$v)},expression:"creator.shuzai_keihi"}},_vm._l((_vm.dataContext.shuzai_keihis),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)]}}])})],1),_c('validation-provider',{attrs:{"name":"金額","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"金額","counter":"","maxlength":100,"error-messages":errors,"clearable":"","dense":"","suffix":"円"},model:{value:(_vm.creator.shuzai_keihi_en),callback:function ($$v) {_vm.$set(_vm.creator, "shuzai_keihi_en", _vm._n($$v))},expression:"creator.shuzai_keihi_en"}})]}}])}),_c('validation-provider',{attrs:{"name":"移動時間","rules":"max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"移動時間","counter":"","maxlength":2,"error-messages":errors,"clearable":"","dense":"","suffix":"時間","hint":"（当日出発支店｛事務所・宿など｝からの時間）"},model:{value:(_vm.creator.idou_zikan),callback:function ($$v) {_vm.$set(_vm.creator, "idou_zikan", _vm._n($$v))},expression:"creator.idou_zikan"}})]}}])}),_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"追加取材"}},[_c('validation-provider',{attrs:{"name":"追加取材","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.creator.tsuika_shuzai),callback:function ($$v) {_vm.$set(_vm.creator, "tsuika_shuzai", $$v)},expression:"creator.tsuika_shuzai"}},_vm._l((_vm.dataContext.bools),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)]}}])})],1),_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"クラウド利用"}},[_c('validation-provider',{attrs:{"name":"クラウド利用","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.creator.cloud_riyou),callback:function ($$v) {_vm.$set(_vm.creator, "cloud_riyou", $$v)},expression:"creator.cloud_riyou"}},_vm._l((_vm.dataContext.cloud_riyous),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)]}}])})],1),_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.creator.bikou),callback:function ($$v) {_vm.$set(_vm.creator, "bikou", $$v)},expression:"creator.bikou"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }