var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.onCancelClicked}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onSubmitClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-send")]),_vm._v(" 送信する ")],1)],1),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('v-form',{ref:"form"},[_c('div',{staticClass:"mb-4 text-caption"},[_vm._v("(*)は必須")]),_c('validation-provider',{attrs:{"name":"受付者","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.staffs,"label":"*受付者","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '受付者 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.uketsukesha),callback:function ($$v) {_vm.$set(_vm.creator, "uketsukesha", $$v)},expression:"creator.uketsukesha"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"都道府県","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.prefs,"label":"*都道府県","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '都道府県 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.pref),callback:function ($$v) {_vm.$set(_vm.creator, "pref", $$v)},expression:"creator.pref"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"地域","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"地域","counter":"","maxlength":100,"error-messages":errors,"clearable":"","dense":""},model:{value:(_vm.creator.area),callback:function ($$v) {_vm.$set(_vm.creator, "area", $$v)},expression:"creator.area"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"顧客名","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.getKokyakus(_vm.creator),"label":"*顧客名","prepend-inner-icon":!_vm.isEmpty(_vm.creator.kokyaku_code) ? "mdi-check" : "","rules":[function (v) { return !!v || '顧客名 は必須です'; }],"counter":"","maxlength":100,"error-messages":errors,"clearable":"","required":"","dense":""},on:{"change":function($event){return _vm.onKokyakuNameChanged(_vm.creator)}},model:{value:(_vm.creator.kokyaku_name),callback:function ($$v) {_vm.$set(_vm.creator, "kokyaku_name", $$v)},expression:"creator.kokyaku_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"受付種別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.uketsuke_shubetsus,"label":"*受付種別","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '受付種別 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.uketsuke_shubetsu),callback:function ($$v) {_vm.$set(_vm.creator, "uketsuke_shubetsu", $$v)},expression:"creator.uketsuke_shubetsu"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"内容","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"内容","counter":"","maxlength":1000,"error-messages":errors,"clearable":""},model:{value:(_vm.creator.naiyou),callback:function ($$v) {_vm.$set(_vm.creator, "naiyou", $$v)},expression:"creator.naiyou"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"対応方法","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.taiou_houhous,"label":"*対応方法","item-text":"name","item-value":"id","rules":[function (v) { return !!v || '対応方法 は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.creator.taiou_houhou),callback:function ($$v) {_vm.$set(_vm.creator, "taiou_houhou", $$v)},expression:"creator.taiou_houhou"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"対応予定日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"*対応予定日","rules":[function (v) { return !!v || '対応予定日 は必須です'; }],"error-messages":errors},model:{value:(_vm.creator.taiou_yoteibi),callback:function ($$v) {_vm.$set(_vm.creator, "taiou_yoteibi", $$v)},expression:"creator.taiou_yoteibi"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"対応期日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"対応期日","error-messages":errors},model:{value:(_vm.creator.taiou_kizitsu),callback:function ($$v) {_vm.$set(_vm.creator, "taiou_kizitsu", $$v)},expression:"creator.taiou_kizitsu"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.creator.bikou),callback:function ($$v) {_vm.$set(_vm.creator, "bikou", $$v)},expression:"creator.bikou"}})]}}],null,true)})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }