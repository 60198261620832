
<template>
  <v-card flat>
    <v-card-title>
      <v-btn color="warning" text @click="onCancelClicked"> 閉じる </v-btn>
      <v-spacer></v-spacer>
      {{ label }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onSubmitClicked">
        <v-icon left>mdi-email-send</v-icon>

        送信する
      </v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <v-form v-if="loaded" ref="form">
            <div class="mb-4 text-caption">(*)は必須</div>
            <validation-provider v-slot="{ errors }" name="報告者" rules="required">
              <v-select
                v-model="creator.houkokusha"
                :items="dataContext.staffs"
                label="*報告者"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || '報告者 は必須です']"
                :error-messages="errors"
                required
                outlined
                dense
              />
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
              <v-combobox
                v-model="creator.kokyaku_name"
                :items="getKokyakus(creator)"
                label="*顧客名"
                :prepend-inner-icon="!isEmpty(creator.kokyaku_code) ? `mdi-check` : ``"
                :rules="[(v) => !!v || '顧客名 は必須です']"
                counter
                :maxlength="100"
                :error-messages="errors"
                clearable
                required
                dense
                @change="onKokyakuNameChanged(creator)"
              />
            </validation-provider>

            <XFieldSet class="mb-4" label="導入日時">
              <validation-provider v-slot="{ errors }" name="導入日時" rules="">
                <DateTime label="導入日時" v-model="creator.dounyuubi" :error-messages="errors">
                  <template v-slot="{ click }"
                    ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ creator.dounyuubi | dateTime }} </v-btn>
                  </template>
                </DateTime>
              </validation-provider>
            </XFieldSet>

            <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
              <v-textarea v-model="creator.bikou" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
            </validation-provider>
          </v-form>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },

    facilityCode: {
      type: String,
    },

    demoCode: {
      type: String,
    },

    label: {
      type: String,
      default: () => '導入日程報告',
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    kokyaku_code() {
      if (this.facilityCode != null) return this.facilityCode;
      return this.$route.params.kokyaku_code;
    },
  },
  data: () => ({
    initializing: true,

    creator: {
      loading: false,

      houkokusha: null,
      kokyaku_code: null,
      kokyaku_name: null,
      dounyuubi: new Date(),
      bikou: null,
    },
  }),
  methods: {
    init() {
      if (this.facilityCode != null && this.demoCode != null) {
        //
        this.get('facility/demo', {
          facilityCode: this.facilityCode,
          demoCode: this.demoCode,
        }).then((success) => {
          let data = success.data;

          this.creator.kokyaku_code = data.facility.code;
          this.creator.kokyaku_name = data.facility.name;
        });
      }
    },

    onLoaded() {
      this.initializing = true;
      this.setKokyakuData(this.creator, this.kokyaku_code);

      this.init();
      this.initializing = false;
    },
    /**
     * フォームクリアイベント
     */
    onClearClicked() {
      this.form.reset();
    },
    /**
     * フォーム決定イベント
     */
    async onSubmitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('送信してよろしいですか？')) return;
      //
      let request = this.creator;
      request.demoCode = this.demoCode;
      request.dounyuubi = this.convertDate(this.creator.dounyuubi);

      this.post('form/dounyuu_nittei_houkoku', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit');
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    /** フォーム変更監視 */
    creator: {
      handler: function () {
        this.setFormChanged(true);
      },
      deep: true,
    },
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>