
<template>
  <v-card flat>
    <v-card-title>
      <v-btn color="warning" text @click="onCancelClicked"> 閉じる </v-btn>
      <v-spacer></v-spacer>
      {{ label }}
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onSubmitClicked">
        <v-icon left>mdi-email-send</v-icon>
        送信する
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <div class="mb-4 text-caption">(*)は必須</div>

        <validation-provider v-slot="{ errors }" name="報告者" rules="required">
          <v-select
            v-model="creator.houkokusha"
            :items="dataContext.staffs"
            label="*報告者"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '報告者 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
          <v-select
            v-model="creator.pref"
            :items="dataContext.prefs"
            label="*都道府県"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '都道府県 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="地域" rules="max:100">
          <v-text-field v-model="creator.area" label="地域" counter :maxlength="100" :error-messages="errors" clearable dense />
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
          <v-combobox
            v-model="creator.kokyaku_name"
            :items="getKokyakus(creator)"
            label="*顧客名"
            :prepend-inner-icon="!isEmpty(creator.kokyaku_code) ? `mdi-check` : ``"
            :rules="[(v) => !!v || '顧客名 は必須です']"
            counter
            :maxlength="100"
            :error-messages="errors"
            clearable
            required
            dense
            @change="onKokyakuNameChanged(creator)"
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="プラン" rules="required">
          <v-select
            v-model="creator.dounyuu_plan"
            :items="dataContext.dounyuu_plans"
            label="*プラン"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'プラン は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="導入者" rules="required">
          <v-select
            v-model="creator.dounyuusha"
            :items="dataContext.staffs"
            label="*導入者"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '導入者 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="取材者" rules="required">
          <v-select
            v-model="creator.shuzaisha"
            :items="dataContext.staffs"
            label="*取材者"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '取材者 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>
        <XFieldSet class="mb-4" label="*リ―ス用紙">
          <validation-provider v-slot="{ errors }" name="リ―ス用紙" rules="required">
            <v-radio-group v-model="creator.lease_youshi" row :rules="[(v) => !!v || 'リ―ス用紙 は必須です']" :error-messages="errors">
              <v-radio v-for="(item, index) in dataContext.lease_youshis" :key="index" :label="item.name" :value="item.id"></v-radio>
            </v-radio-group>
          </validation-provider>
        </XFieldSet>
        <validation-provider v-slot="{ errors }" name="提出待ち理由" rules="max:1000">
          <v-textarea
            outlined
            v-model="creator.lease_youshi_riyuu"
            label="提出待ち理由"
            counter
            :maxlength="1000"
            :error-messages="errors"
            clearable
          />
        </validation-provider>

        <XFieldSet class="mb-4" label="ドメイン">
          <validation-provider v-slot="{ errors }" name="ドメイン" rules="max:100">
            <v-radio-group v-model="creator.domain_shutoku" :error-messages="errors" row>
              <v-radio v-for="(item, index) in dataContext.domain_shutokus" :key="index" :label="item.name" :value="item.id"></v-radio>
            </v-radio-group>
          </validation-provider>
        </XFieldSet>

        <validation-provider v-slot="{ errors }" name="ドメイン名" rules="max:100">
          <v-text-field
            v-model="creator.domain_name"
            label="ドメイン名"
            counter
            :maxlength="100"
            :error-messages="errors"
            clearable
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="domain" rules="">
          <v-select
            v-model="creator.domain_suffix"
            :items="dataContext.domain_suffixies"
            label="domain"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="取材時間" rules="required|max:2">
          <v-text-field
            type="number"
            v-model.number="creator.shuzai_zikan"
            label="*取材時間"
            :rules="[(v) => !!v || '時間 は必須です']"
            counter
            :maxlength="2"
            :error-messages="errors"
            clearable
            dense
            suffix="時間"
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="構成担当" rules="">
          <v-select
            v-model="creator.kousei_tantou"
            :items="dataContext.staffs"
            label="構成担当"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="構成提出日" rules="">
          <DateTime label="構成提出日" v-model="creator.kousei_teishutubi" :error-messages="errors" />
        </validation-provider>

        <XFieldSet class="mb-4" label="取材経費">
          <validation-provider v-slot="{ errors }" name="取材経費" rules="">
            <v-radio-group v-model="creator.shuzai_keihi" :error-messages="errors" row>
              <v-radio v-for="(item, index) in dataContext.shuzai_keihis" :key="index" :label="item.name" :value="item.id"></v-radio>
            </v-radio-group>
          </validation-provider>
        </XFieldSet>

        <validation-provider v-slot="{ errors }" name="金額" rules="">
          <v-text-field
            type="number"
            v-model.number="creator.shuzai_keihi_en"
            label="金額"
            counter
            :maxlength="100"
            :error-messages="errors"
            clearable
            dense
            suffix="円"
          />
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="移動時間" rules="max:2">
          <v-text-field
            type="number"
            v-model.number="creator.idou_zikan"
            label="移動時間"
            counter
            :maxlength="2"
            :error-messages="errors"
            clearable
            dense
            suffix="時間"
            hint="（当日出発支店｛事務所・宿など｝からの時間）"
          />
        </validation-provider>
        <XFieldSet class="mb-4" label="追加取材">
          <validation-provider v-slot="{ errors }" name="追加取材" rules="">
            <v-radio-group v-model="creator.tsuika_shuzai" :error-messages="errors" row>
              <v-radio v-for="(item, index) in dataContext.bools" :key="index" :label="item.name" :value="item.id"></v-radio>
            </v-radio-group>
          </validation-provider>
        </XFieldSet>

        <XFieldSet class="mb-4" label="クラウド利用">
          <validation-provider v-slot="{ errors }" name="クラウド利用" rules="">
            <v-radio-group v-model="creator.cloud_riyou" :error-messages="errors" row>
              <v-radio v-for="(item, index) in dataContext.cloud_riyous" :key="index" :label="item.name" :value="item.id"></v-radio>
            </v-radio-group>
          </validation-provider>
        </XFieldSet>

        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
          <v-textarea v-model="creator.bikou" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
        </validation-provider>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },

    facilityCode: {
      type: String,
    },

    hpCoverageCode: {
      type: String,
    },

    label: {
      type: String,
      default: () => '導入取材完了報告',
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    kokyaku_code() {
      if (this.facilityCode != null) return this.facilityCode;
      return this.$route.params.kokyaku_code;
    },
  },
  data: () => ({
    initializing: true,

    creator: {
      loading: false,

      houkokusha: null,
      pref: null,
      area: null,
      kokyaku_code: null,
      kokyaku_name: null,
      dounyuu_plan: '1',
      dounyuusha: null,
      shuzaisha: null,
      lease_youshi: null,
      lease_youshi_riyuu: null,
      domain_shutoku: null,

      domain_name: null,
      domain_suffix: '1',
      shuzai_zikan: null,
      kousei_tantou: null,
      kousei_teishutubi: null,

      shuzai_keihi: '1',
      shuzai_keihi_en: null,
      idou_zikan: null,
      tsuika_shuzai: '2',
      cloud_riyou: '1',
      bikou: null,
    },
  }),
  methods: {
    init() {
      if (this.facilityCode != null && this.hpCoverageCode != null) {
        //
        this.get('facility/hpCoverage', {
          facilityCode: this.facilityCode,
          hpCoverageCode: this.hpCoverageCode,
        }).then((success) => {
          let data = success.data;

          this.creator.kokyaku_code = data.facility.code;
          this.creator.kokyaku_name = data.facility.name;

          this.creator.pref = data.facility.prefCode;
          this.creator.area = data.facility.areaName;
        });
      }
    },

    onLoaded() {
      this.initializing = true;
      this.setKokyakuData(this.creator, this.kokyaku_code);

      this.init();

      this.initializing = false;
    },
    /**
     * フォームクリアイベント
     */
    onClearClicked() {
      this.form.reset();
    },
    /**
     * フォーム決定イベント
     */
    async onSubmitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('送信してよろしいですか？')) return;
      //
      let request = this.creator;
      request.hpCoverageCode = this.hpCoverageCode;
      request.kousei_teishutubi = this.convertDate(this.creator.kousei_teishutubi);

      //
      this.post('form/dounyuu_shuzai_kanryou_houkoku', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit');
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    /** フォーム変更監視 */
    creator: {
      handler: function () {
        this.setFormChanged(true);
      },
      deep: true,
    },
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>