<template>
  <v-card flat>
    <v-card-title>
      <v-btn color="warning" text @click="onCancelClicked"> 閉じる </v-btn>
      <v-spacer></v-spacer>
      {{ label }}
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onSubmitClicked">
        <v-icon left>mdi-email-send</v-icon>
        送信する
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <div class="mb-4 text-caption">(*)は必須</div>
        <validation-provider v-slot="{ errors }" name="報告者" rules="required">
          <v-select
            v-model="creator.houkokusha"
            :items="dataContext.staffs"
            label="*報告者"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '報告者 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="支店" rules="required">
          <v-select
            v-model="creator.shiten"
            :items="dataContext.shitens"
            label="*支店"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '支店 は必須です']"
            :error-messages="errors"
            required
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
          <v-select
            v-model="creator.pref"
            :items="dataContext.prefs"
            label="*都道府県"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || '都道府県 は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="地域" rules="max:100">
          <v-text-field v-model="creator.area" label="地域" counter :maxlength="100" :error-messages="errors" clearable dense />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
          <v-combobox
            v-model="creator.kokyaku_name"
            :items="getKokyakus(creator)"
            label="*顧客名"
            :prepend-inner-icon="!isEmpty(creator.kokyaku_code) ? `mdi-check` : ``"
            :rules="[(v) => !!v || '顧客名 は必須です']"
            counter
            :maxlength="100"
            :error-messages="errors"
            clearable
            required
            dense
            @change="onKokyakuNameChanged(creator)"
          />
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="あすなろ" rules="required">
          <v-select
            v-model="creator.asunaro_type"
            :items="dataContext.asunaro_types"
            label="*あすなろ"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'あすなろ は必須です']"
            :error-messages="errors"
            required
            outlined
            dense
          />
        </validation-provider>

        <XFieldSet class="mb-4" label="すけさん・かくさん">
          <validation-provider v-slot="{ errors }" name="すけさん・かくさん" rules="">
            <v-radio-group v-model="creator.sukesan_kakusan" :error-messages="errors" row>
              <v-radio v-for="(item, index) in dataContext.sukesan_kakusans" :key="index" :label="item.name" :value="item.id"></v-radio>
            </v-radio-group>
          </validation-provider>
        </XFieldSet>

        <validation-provider v-slot="{ errors }" name="稼働日" rules="required">
          <DateTime label="*稼働日" :rules="[(v) => !!v || '稼働日 は必須です']" v-model="creator.kadoubi" :error-messages="errors" />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
          <v-textarea v-model="creator.bikou" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
        </validation-provider>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },

    facilityCode: {
      type: String,
    },

    label: {
      type: String,
      default: () => 'クラウド稼働報告',
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    kokyaku_code() {
      if (this.facilityCode != null) return this.facilityCode;
      return this.$route.params.kokyaku_code;
    },
  },
  data: () => ({
    initializing: true,

    creator: {
      loading: false,

      houkokusha: null,
      shiten: null,
      pref: null,
      area: null,
      kokyaku_code: null,
      kokyaku_name: null,
      asunaro_type: null,
      sukesan_kakusan: null,
      kadoubi: null,

      bikou: null,
      email: null,
    },
  }),
  methods: {
    onLoaded() {
      this.initializing = true;
      this.setKokyakuData(this.creator, this.kokyaku_code);

      this.initializing = false;
    },
    /**
     * フォームクリアイベント
     */
    onClearClicked() {
      this.form.reset();
    },
    /**
     * フォーム決定イベント
     */
    async onSubmitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //

      let request = this.creator;
      request.kadoubi = this.convertDate(this.creator.kadoubi);

      this.post('form/cloud_kadou_houkoku', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit');
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    /** フォーム変更監視 */
    creator: {
      handler: function () {
        this.setFormChanged(true);
      },
      deep: true,
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>